
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/pages/[cms]",
      function () {
        return require("private-next-pages/pages/[cms].js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/pages/[cms]"])
      });
    }
  